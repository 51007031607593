import {
  Underline,
  underlineHoverStyles,
  focusStateStyles,
} from '@/components/underline';
import { styled } from '@/stitches.config';
import Link from 'next/link';

type Props = {
  children: React.ReactNode;
  isActive?: boolean;
  invert?: boolean;
  href: string;
  size?: number;
  isBlank?: boolean;
  scroll?: boolean;
};

export function NavLink({
  children,
  href,
  isActive = false,
  invert = false,
  scroll = true,
  isBlank,
}: Props) {
  return (
    <Link href={href} passHref scroll={scroll}>
      <Anchor
        isActive={isActive}
        invert={invert}
        target={isBlank ? '_blank' : '_self'}
      >
        {children}
        <Underline css={{ position: 'relative' }} />
      </Anchor>
    </Link>
  );
}

const Anchor = styled('a', {
  position: 'relative',
  color: '$foreground',
  fontFamily: '$neutral',
  fontSize: '$4',
  textDecoration: 'none',
  whiteSpace: 'nowrap',

  display: 'block',
  overflow: 'hidden',
  padding: '$1 $3 0 $3',
  borderBottom: '2px solid transparent',

  '@bp3': {
    fontSize: '$2',
    lineHeight: 1.5,
  },

  '@bp4': {
    fontSize: '$3',
  },

  '&:focus-visible': {
    ...focusStateStyles,
    [`${Underline} > span`]: underlineHoverStyles,
  },

  '&:hover, &:active': {
    [`${Underline} > span`]: underlineHoverStyles,
  },

  variants: {
    // active page indicator
    isActive: {
      true: {
        [`${Underline} > span`]: underlineHoverStyles,
      },
    },
    invert: {
      true: {
        color: '$background',
      },
    },
  },

  compoundVariants: [
    {
      invert: true,
      css: {
        '&::after': {},
      },
    },
  ],
});
