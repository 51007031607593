import create from 'zustand';

type TResearchPageCoverTheme = 'light' | 'dark' | null;
interface HeaderStore {
  isIntersectingWithCover: boolean;
  setIsIntersectingWithCover: (isIntersecting: boolean) => void;
  researchPageCoverTheme: TResearchPageCoverTheme;
  setResearchPageCoverTheme: (
    researchPageCoverTheme: TResearchPageCoverTheme
  ) => void;
}

export const useHeaderStore = create<HeaderStore>((set) => ({
  isIntersectingWithCover: false,
  setIsIntersectingWithCover: (isIntersectingWithCover) =>
    set({ isIntersectingWithCover }),
  researchPageCoverTheme: null,
  setResearchPageCoverTheme: (researchPageCoverTheme) =>
    set({ researchPageCoverTheme }),
}));
