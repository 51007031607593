import { styled } from '@/stitches.config';

const largeGridStyles = {
  gridTemplateColumns: `
      [full-start] minmax(0, 0)
      [wide-start] minmax(0, 1fr)
      [main-start] repeat(14, minmax(0, 1fr)) [main-end]
      minmax(0, 1fr) [wide-end]
      minmax(0, 0) [full-end]
    `,
  gridColumnGap: '$6',
} as const;

export const GridContainer = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column dense',
  gridTemplateColumns: `
    [full-start] minmax(0, $space$2)
    [main-start wide-start] minmax(0, 1fr) [main-end wide-end]
    minmax(0, $space$2) [full-end]
  `,
  gridColumnGap: '$2',

  '@bp3': largeGridStyles,

  '@bp4': {
    gridColumnGap: '$8',
  },

  '& > *': {
    gridColumn: 'main',
  },
  variants: {
    useLargeGridStylesAtBp2: {
      true: {
        '@bp2': largeGridStyles,
      },
    },
    limitMaxWidth: {
      true: {
        maxWidth: 1920,
      },
    },
  },
  defaultVariants: { useLargeGridStylesAtBp2: false, limitMaxWidth: false },
});

GridContainer.displayName = 'GridContainer';
