import { useMobileMenuState } from '@/hooks/use-mobile-menu-state';
import { useScrollDirection } from '@/hooks/use-scroll-direction';
import { useScrollThreshold } from '@/utils/use-scroll-threshold';
import { useAnimation } from 'framer-motion';
import { useTheme } from 'next-themes';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { DesktopHeader } from '@/components/header/desktop';
import { MobileHeader } from '@/components/header/mobile';
import { useHeaderStore } from '@/hooks/useHeaderStore';

// values taken from https://cubic-bezier.com/
// TODO move to shared file
const easeOut = [0, 0, 0.58, 1];
const easeIn = [0.42, 0, 1, 1];
const ease = [0.25, 0.1, 0.25, 1];

type HeaderProps = {
  previousPath: string;
};

export function Header({ previousPath }: HeaderProps) {
  const [isHoveringHeader, setHoveringHeader] = useState(false);
  const { scrollDirection } = useScrollDirection({ thresholdPixels: 2 });
  const hasPassedThreshold = useScrollThreshold(10);
  const isTouch = useMedia('(pointer: coarse)', false);

  // @TODO
  // https://linear.app/cleverfranke/issue/CFW-401/bug-hamburger-menu-color-is-wrong
  // useMedia is useless on the initial render. This causes a hydration error.
  // I'm not sure if it's related to the navigation using the wrong colors. But something to look into.
  const isLargeScreen = useMedia('(min-width: 840px)', true);

  const { isIntersectingWithCover, researchPageCoverTheme } = useHeaderStore(
    (state) => ({
      isIntersectingWithCover: state.isIntersectingWithCover,
      researchPageCoverTheme: state.researchPageCoverTheme,
    })
  );
  const router = useRouter();
  const { resolvedTheme } = useTheme();

  const { isMenuOpen } = useMobileMenuState();

  /**
   * The Header navigation is on top of the case experience modal (when exploring by colour) on the Home page.
   * For mobile and ipad views, we want the navigation bar to span shorter than the entire width of the window when on the Home page
   * It makes space for the 'X' button on the case experience modal, so that it's to not overlapping with the header navigation,
   * thus allowing the users to close the modal view. It takes up the entire width of the window otherwise.
   */
  const isHomePage = router.route === '/';

  // What is that 73px? It's the following measurements:
  // 'X' button + 'X' button padding on both sides => 43px + 2*(15px) = 73px
  const headerWidthOnHomePage = isHomePage ? 'calc(100vw - 73px)' : '100%';

  const isLogoCollapsed =
    !isHomePage && hasPassedThreshold && !isHoveringHeader;

  const isBarHidden =
    isHomePage ||
    !hasPassedThreshold ||
    (scrollDirection !== 'up' && !isHoveringHeader) ||
    isMenuOpen;

  const isDesktopNavHidden =
    !isHomePage &&
    hasPassedThreshold &&
    scrollDirection !== 'up' &&
    !isHoveringHeader;

  // handle bar state
  const barControls = useAnimation();
  useEffect(() => {
    if (isBarHidden) {
      barControls.stop();
      if (!hasPassedThreshold) {
        barControls.start({
          opacity: 0,
          transition: { duration: 0.25, ease: ease },
        });
      } else {
        barControls.start({
          y: -100,
          transition: { duration: 0.25, ease: easeIn },
        });
      }
    } else {
      barControls.stop();
      barControls.set({ opacity: 1 });
      barControls.start({
        y: 0,
        transition: { duration: 0.35, ease: easeOut },
      });
    }
  }, [isBarHidden, hasPassedThreshold, isHoveringHeader, barControls]);

  // handle desktop nav state
  const desktopNavControls = useAnimation();
  useEffect(() => {
    if (isDesktopNavHidden) {
      desktopNavControls.stop();
      desktopNavControls.start({
        y: -100,
        transition: { duration: 0.25, ease: easeIn },
      });
    } else {
      desktopNavControls.stop();
      desktopNavControls.start({
        y: 0,
        transition: { duration: 0.35, ease: easeOut },
      });
    }
  }, [isDesktopNavHidden, desktopNavControls]);

  /**
   * The logo should invert (be white on black) when we are on a case page, in light mode, and we are not showing the floating bar.
   * If we are in dark mode (resolvedTheme === 'dark') we don't need to do anything. That is already white on black!
   */

  const isProjectPage = router.route === '/project/[...slug]';
  const isResearchPage = router.route === '/research-wip/[...slug]';
  const isDarkTheme = resolvedTheme === 'dark';

  let shouldInvertUi = false;

  if (isProjectPage) {
    if (!isDarkTheme) {
      if (isIntersectingWithCover) {
        shouldInvertUi = isBarHidden;
      }
    }
  } else if (isResearchPage) {
    if (isIntersectingWithCover) {
      if (isBarHidden) {
        shouldInvertUi =
          researchPageCoverTheme === (isDarkTheme ? 'light' : 'dark');
      } else {
        shouldInvertUi = false;
      }
    }
  }

  if (!isLargeScreen) {
    return (
      <MobileHeader
        logoCollapsed={isLogoCollapsed}
        barControls={barControls}
        logoClickable={!isHomePage}
        // 'shouldInvertUi' is a logic used when in light mode, the header navigation is black on white background
        // The header navigation should invert (be white on black) when on a case cover image
        // The header items should invert again (be black) when in light mode, on a case study page/ case cover image, and the mobile menu is open
        shouldInvertUi={
          shouldInvertUi && isMenuOpen ? !shouldInvertUi : shouldInvertUi
        }
        headerWidth={headerWidthOnHomePage}
      />
    );
  }

  if (isLargeScreen) {
    return (
      <DesktopHeader
        isTouch={isTouch}
        setHoveringHeader={setHoveringHeader}
        barControls={barControls}
        desktopNavControls={desktopNavControls}
        logoClickable={!isHomePage}
        shouldInvertUi={shouldInvertUi}
        isLogoCollapsed={isLogoCollapsed}
        hasNav={!isHomePage}
        headerWidth="100%"
        isPreviousRouteHome={previousPath === '/'}
      />
    );
  }

  return null;
}
